
import imageSrc from './images/sugar-cube.png'; // Import the image
import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import Iceimage from "../src/images/images 1.png";
import { Link } from 'react-router-dom';
import Footer from './footer';



const Home = () => {
  return (
    <Box  >

      
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12} md={12} sm={12} >
          <Box
            sx={{
              margin: "0 auto",
              display: 'flex',
              flexDirection: 'row',
              marginTop: '30px',
              padding:'20px',
              width: '55vw',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              backdropFilter: 'blur(3px)',
              marginBottom:'10px',

            }}>
            <Box sx={{
              backgroundImage: `url(${imageSrc})`,
              backgroundSize: '19%',
              backgroundPosition: 'right',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              flexDirection: 'column',
              '@media screen and (max-width: 430px)':{
                backgroundSize: '32%',
              
              }

            }}>
              <Typography sx={{
                color: 'white', 
                fontSize: '38px', 
                fontWeight: 'bold',
                marginBottom:'10px',
                maxWidth:'90%',
                '@media screen and (max-width: 770px)': {
                  fontSize: '27px'
                },
                '@media screen and (max-width: 590px)': {
                  fontSize: '23px'
                },
                
              }}>
                Tired of Dirty Ice in Your Bar ?
              </Typography>
              <Typography sx={{
                color: 'white',
                fontSize: '25px',
                width:'65%',
                '@media screen and (max-width: 770px)': {
                  fontSize: '20px'
                },
                '@media screen and (max-width: 590px)': {
                  fontSize: '15px'
                }
              }}>
                Clear Ice TM provides you with the best distilled ice solution for Icey Aesthetics
              </Typography>
            </Box>


          </Box>
        </Grid>
        {/* video Grid starts here */}
        <Grid item xs={12} xl={6} md={6} sm={6} sx={{}}>
          <Box textAlign='center' >
            <Typography sx={
              {
                color: 'white',
                fontSize: "30px"
              }
            }>
              Cleae Eyes
            </Typography>
            <Typography sx={
              {
                color: 'white',
                fontSize: "30px"
              }
            }>
              Realize , Clear Ice
            </Typography>
          </Box>
          
          <Box sx={{ width: '300px', height: '210px', overflow: 'hidden', borderRadius: '10px', margin: "0 auto" }}>
          
            <video width="300" height="210" controls loop autoPlay muted>
              <source src="./movie.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button
              sx={{

                top: '10px',
                left: '10px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                borderRadius: '40%',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                cursor: 'pointer',
                zIndex: 1
              }}
            >

              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-play" viewBox="0 0 16 16">
                <path d="M11.643 8.202l-6.363-4.266a.5.5 0 0 0-.635.078A.5.5 0 0 0 4 4.5v7.737a.5.5 0 0 0 .642.47l6.363-4.266a.5.5 0 0 0 0-.834z" />
              </svg>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} xl={6} md={6} sm={6}>
          <Box textAlign='center'>
            <Typography sx={
              {
                color: 'white',
                fontSize: "30px"
              }
            }>
              Cleae Eyes
            </Typography>
            <Typography sx={
              {
                color: 'white',
                fontSize: "30px"
              }
            }>
              Realize , Clear Ice
            </Typography>
          </Box>
          <Box sx={{ width: '300px', height: '210px', overflow: 'hidden', borderRadius: '10px', margin: "0 auto" }}>
            <video width="300" height="210" controls loop autoPlay muted>
              <source src="./movie.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button
              sx={{

                top: '10px',
                left: '10px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                borderRadius: '40%',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                cursor: 'pointer',
                zIndex: 1
              }}
            >

              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-play" viewBox="0 0 16 16">
                <path d="M11.643 8.202l-6.363-4.266a.5.5 0 0 0-.635.078A.5.5 0 0 0 4 4.5v7.737a.5.5 0 0 0 .642.47l6.363-4.266a.5.5 0 0 0 0-.834z" />
              </svg>
            </Button>
          </Box>
        </Grid>
        {/* our line of special */}
        <Grid item xs={12} >
          <Box sx={{ marginTop: 2, marginBottom: 2, textAlign: 'center' }}>
            <Typography sx={{
              fontSize: '30px',
              color: 'white'
            }}>Our Line of Special Ice</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} xl={3} md={6} sm={6}>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '250px',
            height: '280px',
            overflow: 'hidden',
            borderRadius: '10px',
            margin: '0 auto',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(3px)',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <img
              src={Iceimage}
              alt=""
              style={{ maxWidth: '150px', maxHeight: '140px' }}
            />
            <Typography sx={{
              color: 'white',
              fontSize: "16px",
              textAlign: "center"
            }}>
              Cylinder Ice
            </Typography>
            <Typography sx={{
              color: 'white',
              fontSize: "16px",
              textAlign: "center"
            }}>
              Rs . 250/kg
            </Typography>
            <Button component={Link} to="/product1"
              sx={{
                color: 'white',
                fontSize: "16px",
                textAlign: "center",
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                backdropFilter: 'blur(3px)',
                borderRadius: 4,
                marginTop: 1
              }}
            >
              read more
            </Button>
          </Box>

        </Grid>
        <Grid item xs={12} xl={3} md={6} sm={6}>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '250px',
            height: '280px',
            overflow: 'hidden',
            borderRadius: '10px',
            margin: '0 auto',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(3px)',
            alignItems: 'center',
            justifyContent: 'center'
          }}>

            <img
              src={Iceimage}
              alt=""
              style={{ maxWidth: '150px', maxHeight: '140px', justifyContent: 'center' }}
            />


            <Typography sx={
              {
                color: 'white',
                fontSize: "16px",
                textAlign: "center"
              }
            }>
              Cylinder Ice
            </Typography>
            <Typography sx={
              {
                color: 'white',
                fontSize: "16px",
                textAlign: "center"
              }
            }>
              Rs . 250/kg
            </Typography>

            <Button component={Link} to="/product2"
              sx={
                {
                  color: 'white',
                  fontSize: "16px",
                  textAlign: "center",
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  backdropFilter: 'blur(3px)',
                  borderRadius: 4,
                  marginTop: 1
                }
              }>
              read more
            </Button>

          </Box>
        </Grid>
        <Grid item xs={12} xl={3} md={6} sm={6}>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '250px',
            height: '280px',
            overflow: 'hidden',
            borderRadius: '10px',
            margin: '0 auto',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(3px)',
            alignItems: 'center',
            justifyContent: 'center'
          }}>

            <img
              src={Iceimage}
              alt=""
              style={{ maxWidth: '150px', maxHeight: '140px', justifyContent: 'center' }}
            />


            <Typography sx={
              {
                color: 'white',
                fontSize: "16px",
                textAlign: "center"
              }
            }>
              Cylinder Ice
            </Typography>
            <Typography sx={
              {
                color: 'white',
                fontSize: "16px",
                textAlign: "center"
              }
            }>
              Rs . 250/kg
            </Typography>

            <Button
              component={Link} to="/product2"
              sx={
                {
                  color: 'white',
                  fontSize: "16px",
                  textAlign: "center",
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  backdropFilter: 'blur(3px)',
                  borderRadius: 4,
                  marginTop: 1
                }
              }>
              read more
            </Button>

          </Box>
        </Grid>
        <Grid item xs={12} xl={3} md={6} sm={6}>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '250px',
              height: '280px',
              overflow: 'hidden',
              borderRadius: '10px',
              margin: '0 auto',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              backdropFilter: 'blur(3px)',
              alignItems: 'center',
              justifyContent: 'center'
            }}>

            <img
              src={Iceimage}
              alt=""
              style={{ maxWidth: '150px', maxHeight: '140px', justifyContent: 'center' }}
            />


            <Typography sx={
              {
                color: 'white',
                fontSize: "16px",
                textAlign: "center"
              }
            }>
              Cylinder Ice
            </Typography>
            <Typography sx={
              {
                color: 'white',
                fontSize: "16px",
                textAlign: "center"
              }
            }>
              Rs . 250/kg
            </Typography>

            <Button
              component={Link} to="/product2"
              sx={
                {

                  color: 'white',
                  fontSize: "16px",
                  textAlign: "center",
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  backdropFilter: 'blur(3px)',
                  borderRadius: 4,
                  marginTop: 1
                }
              }>
              read more
            </Button>

          </Box>
        </Grid>
      </Grid>

      <Footer />
    </Box>
  );
};

export default Home;
