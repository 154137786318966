// Footer.js

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Instagram, WhatsApp, Facebook } from '@mui/icons-material'; // Import Material-UI icons
import imagecube from './images/3d-cube.png';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        padding: '20px',
        textAlign: 'center',
        marginTop: 8,
        bottom: 0,
        maxWidth: "100vw",
      }}
    >
      <Grid container spacing={7}>
        <Grid item xs={9} md={5} sx={{ margin: '0 auto' }}>
          <img
            src={imagecube}
            alt=""
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          />
          <Typography
            sx={{
              fontSize: "28px",
              color: 'white',
              fontWeight: 'bold'
            }}>
            Clear Ice
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              color: 'white',
            }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus molestias tenetur natus eius, facere nihil omnis recusandae repellat vitae esse deserunt et alias aut iste culpa sunt optio perferendis officia?
          </Typography>
        </Grid>
        <Grid item xs={9} md={3} sx={{ margin: '0 auto' }}>
          <Typography
            sx={{
              fontSize: "24px",
              borderBottom: "1px solid white",
              marginBottom: 2
            }}>
            Go To
          </Typography>
          <Typography>
            Home
          </Typography>
          <Typography>
            About Us
          </Typography>
          <Typography>
            How we do it
          </Typography>
        </Grid>
        <Grid item xs={9} md={3} sx={{ margin: '0 auto' }}>
          <Typography
            sx={{
              fontSize: "24px",
              borderBottom: "1px solid white",
              marginBottom: 2
            }}>
            Contact us
          </Typography>

          <Typography>
            +91-9293912391
          </Typography>
          <Typography>
            Clearice@gmail.com
          </Typography>
          <Typography>
            abc street, indore
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: "8px",
              marginTop: '14px',
              justifyContent: 'center'
            }}
          >
            <Instagram style={{ fontSize: '30px', color: 'white' }} />
            <WhatsApp style={{ fontSize: '30px', color: 'white' }} />
            <Facebook style={{ fontSize: '30px', color: 'white' }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
