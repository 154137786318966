import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', backdropFilter: 'blur(2px)' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Button component={Link} to="/home" sx={{ color: 'white', textDecoration: 'none', textAlign: 'center', width: '25%' }}>
            Home
          </Button>
          <Button component={Link} to="/aboutus" sx={{ color: 'white', textDecoration: 'none', textAlign: 'center', width: '25%' }}>
            About Us
          </Button>
          <Button component={Link} to="/contactus" sx={{ color: 'white', textDecoration: 'none', textAlign: 'center', width: '25%' }}>
            Contact Us
          </Button>
          <Button
            component={Link}
            to="/how"
            sx={{ color: 'white', textDecoration: 'none', textAlign: 'center', width: '25%' }}
          >
            How We Do It
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
