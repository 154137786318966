// Routes.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import Dashboard from './dashboard';
import Home from './home';
import AboutUs from './aboutus';
import How from './how';
// import Product1 from './products/product1';
import Product2 from './products/product2';
import Contact from './contactus';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/how" element={<How />} />
      <Route path="/contactus" element={<Contact />} />
      <Route path="./products/product2" element={<Product2 />} />
      <Route path="./products/product2" element={<Product2 />} />
    </Routes>
  );
};

export default AppRoutes;
