// AboutUs.js
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import imageSrc from './images/sugar-cube.png'
import Footer from './footer';

const AboutUs = () => {
  return (
    <Box>
    <Grid container>

      <Grid item xs={12} xl={12} md={12} sm={12} >
        
      <Box
            sx={{
              margin: "0 auto",
              display: 'flex',
              flexDirection: 'row',
              marginTop: '30px',
              padding:'10px',
              width: '55vw',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              backdropFilter: 'blur(3px)',
              marginBottom:'10px',

            }}>
            <Box sx={{
              backgroundImage: `url(${imageSrc})`,
              backgroundSize: '19%',
              backgroundPosition: 'right',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              flexDirection: 'column',
              '@media screen and (max-width: 430px)':{
                backgroundSize: '32%',
              
              }

            }}>
              <Typography sx={{
                color: 'white', 
                fontSize: '38px', 
                fontWeight: 'bold',
                marginBottom:'10px',
                maxWidth:'90%',
                '@media screen and (max-width: 770px)': {
                  fontSize: '27px'
                },
                '@media screen and (max-width: 590px)': {
                  fontSize: '23px'
                },
                
              }}>
               About us
              </Typography>
              <Typography sx={{
                color: 'white',
                fontSize: '25px',
                width:'65%',
                '@media screen and (max-width: 770px)': {
                  fontSize: '20px'
                },
                '@media screen and (max-width: 590px)': {
                  fontSize: '15px'
                }
              }}>
                Clear Ice TM provides you with the best distilled ice solution for Icey Aesthetics
              </Typography>
            </Box>


          </Box>
       
      </Grid>
      
    </Grid>
    <Footer/>
    </Box>
  );
};

export default AboutUs;
