import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import TopBar from './topBar';
import Header from './header';
import AppRoutes from './routes'; // Import the AppRoutes component
import './App.css';
import backgroundImage from './images/ice.png';
import { Box } from '@mui/material';


const App: React.FC = () => {
  return (
    <Router>
      <Box className="app" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', overflow: 'auto' }}>
        <TopBar  />
        <Header />
        <AppRoutes /> 
        
      </Box>
      {/* <Footer/> */}
    </Router>
  );
};

export default App;
