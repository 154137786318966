// AboutUs.js
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import imageSrc from './images/sugar-cube.png'
import Footer from './footer';


const How = () => {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} xl={12} md={12} sm={12} >
          <Box sx={{
              margin: "0 auto",
              display: 'flex',
              flexDirection: 'row',
              marginTop: '30px',
              height: '40vh',
              width: '55vw',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              backdropFilter: 'blur(3px)',
          }}>
            <Box>
              <Typography sx={{
                color: 'white',
                fontSize: '34px',
                fontWeight: 'bold',
                '@media screen and (max-width: 770px)': {
                  fontSize: '27px'
                },
                '@media screen and (max-width: 590px)': {
                  fontSize: '23px'
                }

              }}>
                How we do it
              </Typography>
              <Typography sx={{
                color: 'white',
                fontSize: '20px',
                '@media screen and (max-width: 770px)': {
                  fontSize: '20px'
                },
                '@media screen and (max-width: 590px)': {
                  fontSize: '15px'
                }
              }}>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptates autem perferendis dolore perspiciatis repellat,
              </Typography>
            </Box>
            <Box sx={{
                justifyContent: 'center',
                alignContent: 'center'
            }}>
              <img
                className='image1'
                src={imageSrc}
                alt=""
                style={{
                  maxWidth: '98%', maxHeight: '80%',

                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Footer/>
    </Box>
  );
};

export default How;
