// AboutUs.js
import { Box, Grid, TextField, Typography, Button } from '@mui/material';
import React from 'react';
import Footer from './footer';
// import callIcon from './callIcon.png';
// import emailIcon from './images/emailIcon.png';

const Contact = () => {
  return (
    <Box sx={{ marginTop: 5 }}>
      <Grid container>
        <Grid item md={8} xs={8} sx={{ margin: '0 auto' }}>
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              backdropFilter: 'blur(3px)',
              border: 'white 0.5px solid',
              borderRadius: '10px',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
              padding: '5%',
            }}
          >
            <Typography sx={{ color: 'white', fontSize: '28px' }}> Have a Question? Let Us Know</Typography>
            <TextField
              sx={{ marginBottom: 2 }}
              id="email"
              label="Email"
              variant="outlined"
              InputLabelProps={{
                style: { color: 'white' },
              }}
              InputProps={{
                style: { color: 'white' },
              }}
            />
            <TextField
              multiline
              sx={{ marginBottom: 2 }}
              id="query"
              label="Your Query"
              variant="outlined"
              InputLabelProps={{
                style: { color: 'white' },
              }}
              InputProps={{
                style: { color: 'white' },
              }}
            />
            <Button
              variant="contained"
              sx={{
                marginTop: 2,
                width: 120,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                backdropFilter: 'blur(3px)',
                border:'0.5px white solid'
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
        {/* <Grid item xs={7} sx={{ margin: '0 auto' }}>
          <Divider
            sx={{
              margin: '20px 0',
              '&::before, &::after': {
                borderColor: 'white',
              },
              borderBottomWidth: 2, 
              color: 'white', 
            }}
          >
            Or
          </Divider>
        </Grid> */}
        {/* <Grid item md={6} xs={3}>
          <Box sx={{
             margin:'0 auto',
          }}>
            <Button 
            
              startIcon={<Avatar src={emailIcon} />}
              sx={{ 
               
                  background: '#2726264e', 
                  border: '4px solid #f9f9f9', 
                  borderRadius: "25px", 
                  fontSize: "14px", 
                  color: 'white', 
                  width: '250px', 
            
                 
                  fontWeight: 'bold',
                  }} href="mailto:info@quadraedge.in">
                  info@quadraedge.in
          </Button>
          </Box>
        </Grid> */}
      </Grid>
      <Footer/>
    </Box>
  );
};

export default Contact;
