// AboutUs.js
import { Grid, Typography } from '@mui/material';
import React from 'react';
import imageSrc1 from '../images/images 1.png'

const Product2 = () => {
  return (
    <Grid
    container
    sx={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      marginLeft: 40,
      gap: 1,
      height: '48vh',
      width: '55vw',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      backdropFilter: 'blur(3px)',
      textAlign: 'center',
      flexWrap: 'wrap'
    }}
  >
    <Grid 
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width:'280px',
      justifyContent:'center',
      textAlign:'left'
    
    }}>
      <Typography sx={{ color: 'white', fontSize: '38px' }}>
        Cylinder Ice
      </Typography>
      <Typography sx={{ color: 'white', fontSize: '18px' }}>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Debitis asperioreat!
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Debitis asperioreat!
      </Typography>
    </Grid>
    <Grid  sx={{ paddingTop: 5 }}>
      <img
        src={imageSrc1}
        alt=""
        style={{ maxWidth: '70%', maxHeight: '60%' }}
      />
    </Grid>
  </Grid>
  );
};

export default Product2;
