
import { Box, Typography } from '@mui/material';
import React from 'react';
import Timage from './images/cube 1.png'


const TopBar = () => {
  return (
    <Box 
    position="sticky"
      sx={{
        height: 75,
        background: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
       <img
            src={Timage}
            alt=""
            style={{ maxWidth: '90px', maxHeight: '60px' }}
          />
      <Typography variant="h4" color="white" sx={{
        paddingLeft:2,
      }}>CLEAR ICE</Typography>
    </Box>
  );
};

export default TopBar;
